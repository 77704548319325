body {
  --brand-dark-color: #222831;
  --brand-light-color: #30475e;
  --accent-color: #f05454;
  --white-color: #e8e8e8;
  --black-color: #bd4242;
  --primary-color: #007bff;
  --secondary-color: #6c757d;
  --success-color: #28a745;
  --danger-color: #dc3545;
  --warning-color: #ffc107;
  --info-color: #17a2b8;
  --light-color: #f8f9fa;
  --dark-color: #343a40;
  --muted-color: #6c757d;
  --radius: 4px;
}
