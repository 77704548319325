@import "../Constants.css";

.btn {
  padding: 8px;
  border-radius: var(--radius);
  border: 0;
  box-sizing: border-box;
  /* font-weight: bold; */
  cursor: pointer;
  /* outline: none; */
  overflow: hidden;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  text-align: center;
}

@media (hover: none) {
  .btn {
    outline: none;
  }
}

.btn.big {
  padding: 12px;
  font-size: 16px;
}

/* accent */

.btn.btn-accent {
  background-color: var(--accent-color);
  color: var(--white-color);
  fill: var(--white-color);
}

.btn.btn-accent:active {
  background-color: var(--white-color);
  color: var(--accent-color);
  fill: var(--accent-color);
}

/* white */

.btn.btn-white {
  background-color: var(--white-color);
  color: var(--accent-color);
  fill: var(--accent-color);
}

.btn.btn-white:active {
  background-color: var(--accent-color);
  color: var(--white-color);
  fill: var(--white-color);
}

/* brand-dark */

.btn.btn-brand-dark {
  background-color: var(--brand-dark-color);
  color: var(--white-color);
}

.btn.btn-brand-dark:active {
  background-color: var(--white-color);
  color: var(--brand-dark-color);
}

/* brand-light */

.btn.btn-brand-light {
  background-color: var(--brand-light-color);
  color: var(--white-color);
}

.btn.btn-brand-light:active {
  background-color: var(--white-color);
  color: var(--brand-light-color);
}

.circle {
  align-self: center;
  border-radius: 100px;
  width: 40px;
  height: 40px;
  font-size: 24px;
  line-height: 15px;
  font-weight: bold;
  /* outline: none; cambiar por otro usando el focus*/
  outline: none;
}

@media (hover: hover) {
  .circle {
    margin-top: 10px;
  }
}
