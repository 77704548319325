@import "../Constants.css";

.input {
  padding: 8px;
  border-radius: var(--radius);
  border: 0;
  box-sizing: border-box;
}

@media (hover: none) {
  .input {
    outline: none;
  }
}
