@import "./Components/Button.css";

html,
body,
#root {
  height: 100%;
}

.logo {
  height: 40vmin;
}
