@import "./Constants.css";

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  background-color: var(--brand-dark-color);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

div {
  display: flex;
}

#root {
  justify-content: center;
}

.d-flex {
  display: flex;
  box-sizing: border-box;
}

.col {
  flex-direction: column;
}

.row {
  flex-direction: row;
}

.wrap {
  flex-wrap: wrap;
}

.center {
  justify-content: center;
  align-items: center;
}

.start {
  justify-content: start;
  align-items: center;
}

.f-1 {
  flex: 1;
}

.h-100 {
  min-height: 100%;
}

.w-100 {
  width: 100%;
}

.p-16 {
  padding: 16px;
}

.p-32 {
  padding: 32px;
}

.p-64 {
  padding: 64px;
}

.m-2 {
  margin: 2px;
}

.m-4 {
  margin: 4px;
}

.m-8 {
  margin: 8px;
}

.m-16 {
  margin: 16px;
}

.m-32 {
  margin: 32px;
}

.m-64 {
  margin: 64px;
}

.mr-8 {
  margin-right: 8px;
}

.title {
  color: var(--white-color);
  font-size: 48px;
  padding: 16px;
}

.container {
  max-width: 250px;
  width: 80%;
}

.error {
  background-color: var(--danger-color);
  border-radius: var(--radius);
  color: var(--white-color);
  justify-content: center;
  padding: 4px;
}

.ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
